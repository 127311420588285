<template>
  <div class="login">
    <div class="title">登录</div>
    <div class="bar"></div>
    <div class="box">
      <div class="list">
        <div class="listTitle">账号</div>
        <div class="listBox">
          <van-field v-model="parames.mobile" @input="logoSele" placeholder="请输入账号" />
        </div>
      </div>
      <div class="list" v-if="isSelect">
        <div class="listTitle">商户选择</div>
        <div class="listBox" @click="show = true">
          <div class="content">
            <span>{{ merchantName }}</span>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </div>
      <div class="list">
        <div class="listTitle">密码</div>
        <div class="listBox">
          <div class="">
            <van-field v-model="parames.password" type="isPassWord ? 'password' : 'text'" placeholder="请输入密码" />
          </div>
          <img :src="
              isPassWord ? require('../assets/image/off.png') : require('../assets/image/on.png')
            " class="passIcon" @click="isPassWord = !isPassWord" alt="" />
        </div>
      </div>
      <div class="base">
        <div class="forget" @click="$router.push({ path: '/forgetPassword' })">忘记密码</div>
        <div class="btn">
          <van-button type="primary" style="color: #ffffff" round @click="login">
            登录
            <img src="../assets/image/xiangyou2.png" alt="" />
          </van-button>
        </div>
      </div>
    </div>
    <van-action-sheet v-model:show="show" title="请选择登录商户" :close-on-popstate="true" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
export default {
  name: 'login',
  // eslint-disable-next-line space-before-function-paren
  data () {
    return {
      parames: {
        mobile: '',
        password: '',
        loginAdminId: '',
        loginType: 4
      },
      merchantName: '请选择商户',
      isPassWord: true,
      show: false,
      isSelect: false,
      actions: []
    };
  },
  methods: {
    // eslint-disable-next-line space-before-function-paren
    logoSele () {
      if (this.parames.mobile.length == 11) {
        this.$require
          .HttpPost(
            '/purchaseLogin/selectUser',
            {
              mobile: this.parames.mobile,
              loginType: 1
            },
            true
          )
          .then((res) => {
            this.actions = res.data;
            this.isSelect = true;
          });
      } else {
        this.isSelect = false;
      }
    },
    // eslint-disable-next-line space-before-function-paren
    onSelect (event) {
      this.merchantName = event.name;
      this.parames.loginAdminId = event.merchantsAdminId;
      this.show = false;
    },
    // eslint-disable-next-line space-before-function-paren
    login () {
      //  this.$router.replace({ path: '/home' });
      if (!this.parames.mobile) {
        this.$toast('请输入账号');
        return;
      }
      if (!this.parames.password) {
        this.$toast('请输入密码');
        return;
      }
      if (this.isSelect && !this.parames.loginAdminId) {
        this.$toast('请选择登录商户');
        return;
      }
      this.$require.HttpPost('/purchaseLogin/login', this.parames, { isTokn: true, isURl: true }).then((res) => {
        window.localStorage.setItem('propertyToken', res.data.token);
        this.$require.HttpPost('/shop/info', {}, { isTokn: false, isURl: true }).then((data) => {
          window.localStorage.setItem('propertyUserInfo', JSON.stringify(data.data));
          this.$router.replace({ path: '/home' });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url('../assets/image/loginBk.png') no-repeat;
  background-size: 100% 100vh;
  .title {
    font-size: 48px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #010100;
    padding-top: 361px;
    padding-left: 128px;
  }
  .bar {
    width: 611px;
    height: 30px;
    background: #b9f55c;
    border-radius: 30px 0px 0px 0;
    margin-top: 55px;
    margin-left: 139px;
  }
  .box {
    width: 559px;
    background: #ffffff;
    border-radius: 30px 0px 0px 30px;
    padding: 0 64px 0 41px;
    margin-left: 86px;
    .list {
      height: 146px;
      border-bottom: 1px solid #efefef;
      .listTitle {
        font-size: 28px;
        color: #666666;
        padding-top: 30px;
      }
      .listBox {
        font-size: 32px;
        color: #010100;
        position: relative;
        .passIcon {
          width: 38px;
          height: 38px;
          position: absolute;
          right: 0;
          top: 0;
        }
        .van-cell {
          padding: 0;
          margin-top: 20px;
        }
        .content {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          font-size: 26px;
          .van-icon {
            color: #d2d2d2;
          }
        }
      }
    }
    .base {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      padding-bottom: 40px;
      .forget {
        font-size: 28px;
        color: #85c226;
      }
      .btn {
        width: 256px;
        height: 96px;
        font-weight: bold;
        font-family: PingFang-SC-Bold;
        font-size: 32px;
        img {
          width: 38px;

          object-fit: contain;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
